import {
  TOGGLE_MENU,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_THEME,
  SET_NAVIGATION_DATA,
  SEARCH,
  STUDY_DETAILS,
  SET_CURRENT_SCREEN,
  SET_SUPPLY_DATA,
  SET_ROLECONFIG_DATA,
  SET_LANDING_PAGE,
  SET_PARENT_DATA,
  SET_USERROLECONFIG_DATA,
  SET_GROUP_DATA,
  SET_USERNAME,
  SET_ELEMENT_DATA,
  SET_ALERT_DATA,
  SET_RULECONFIG_DATA,
  SET_RULEPARAMETER_DATA,
  SET_DELIVERY_METHOD,
  SET_SELECTED_ROLE_DATA,
  SET_SELECTED_USER_DATA,
} from '../types/types';

export const toggleMenu = () => (dispatch) => {
dispatch({
  type: TOGGLE_MENU,
});
};
export const showLoader = () => (dispatch) => {
dispatch({
  type: SHOW_LOADING,
  payload: true,
});
};
export const hideLoader = () => (dispatch) => {
dispatch({
  type: HIDE_LOADING,
  payload: false,
});
};
export const showNotification = (type, message, description) => (dispatch) => {
dispatch({
  type: SHOW_NOTIFICATION,
  payload: {
    type, message, description, status: true
  },
});
};
export const hideNotification = () => (dispatch) => {
dispatch({
  type: HIDE_NOTIFICATION,
  payload: {
    status: false
  },
});
};

export const setTheme = (theme) => (dispatch) => {
dispatch({
  type: SET_THEME,
  payload: theme,
});
};

export const setGroups = (data) => (dispatch) =>{
dispatch({
  type:SET_GROUP_DATA,
  payload:data
})
}

export const setNavigationData = (data) => (dispatch) => {
dispatch({
  type: SET_NAVIGATION_DATA,
  payload: data
})
};

export const setElementData = (data) => (dispatch) =>{
dispatch({
  type:SET_ELEMENT_DATA,
  payload:data
})
}
export const searchData = (text) => (dispatch) => {
dispatch({
  type: SEARCH,
  payload: text
});
};

export const setCurrentStudyDetails = (data) => (dispatch) => {
dispatch({
  type: STUDY_DETAILS,
  payload: data
});
};

export const setCurrentScreen = (text) => (dispatch) => {
dispatch({
  type: SET_CURRENT_SCREEN,
  payload: text
})
};

export const setSupplyData = (text) => (dispatch) => {
dispatch({
  type: SET_SUPPLY_DATA,
  payload: text
})
};

export const setRoleConfigData = (text) => (dispatch) => {
dispatch({
  type: SET_ROLECONFIG_DATA,
  payload: text
})
};

export const setLandingPages = (text) => (dispatch) => {
dispatch({
  type: SET_LANDING_PAGE,
  payload: text
})
};

export const setParentData = (text) => (dispatch) => {
dispatch({
  type: SET_PARENT_DATA,
  payload: text
})
};

export const userRoleConfigData = (text) => (dispatch) => {
dispatch({
  type: SET_USERROLECONFIG_DATA,
  payload: text
})
};

export const alertSeletedRecord = (text) => (dispatch) => {
dispatch({
  type: SET_ALERT_DATA,
  payload: text
});
};

export const setRuleConfigData = (text) => (dispatch) => {
dispatch({
  type: SET_RULECONFIG_DATA,
  payload: text
})
};

export const setRuleParameterContent = (text) => (dispatch) => {
dispatch({
  type: SET_RULEPARAMETER_DATA,
  payload: text
})
};

export const setDeliveryMethods = (text) => (dispatch) => {
dispatch({
  type: SET_DELIVERY_METHOD,
  payload: text
})
};

export const setRoleData = (text) => (dispatch) => {
dispatch({
  type: SET_SELECTED_ROLE_DATA,
  payload: text
})
};

export const setUserData = (text) => (dispatch) => {
dispatch({
  type: SET_SELECTED_USER_DATA,
  payload: text
})
};