// //RL server 
// export const API_CUBIX_URL ="http://192.168.101.78:8765/cassa";
// //export const API_CUBIX_URL ="https://cubix-service.relevancelab.com/cassa";
// export const API_BASE_URL = "https://apigateway.relevancelab.com";
// // export const API_RESULTSET_URL = "https://rigel-api-prism-resultset-dev.relevancelab.com";
// export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";
// // export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";
// export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";
// //export const API_AUTHENTICATION_URL    = "https://apigateway.relevancelab.com/prismmicro-authentication"
// //export const API_AUTHENTICATION_URL    = "http://192.168.101.78:8765/prismmicro-authentication"
// export const API_SEARCH_URL    = "https://apigateway.relevancelab.com/prismmicro-search";
// export const API_LOGIN_URL    = "https://cubix-service.relevancelab.com/prismmicro-authentication";
// // export const API_AUTHENTICATION_URL    = "http://192.168.101.53:8765/prismmicro-auth";
// // export const API_AUTHENTICATION_URL    = "http://192.168.101.53:9001";
// // export const API_AUTHENTICATION_URL    = "http://192.168.101.78:9001";
// export const API_AUTHENTICATION_URL    = "https://rigel-api-gateway-dev.relevancelab.com/rigel-auth";
// // export const API_AUTHENTICATION_URL    = "https://rigel-sysadmin-api-gateway-dev.relevancelab.com";
// export const API_ALERT="https://rigel-api-gateway-dev.relevancelab.com/rigel-alert"
// export const APP_AUTH="https://rigel-auth-dev.relevancelab.com/"

//original url 29-5-2024
// export const API_RISK="https://cassa3-0-api-csc.web-uat.bms.com"

// export const API_RESULTSET_URL_AUTH = "https://apigateway.relevancelab.com/prismmicro-resultset";//for get list of users
// // export const API_DASHBOARD_URL = "https://rigel-api-gateway-dev.relevancelab.com/rigel-dashboard";
// // export const API_CASSA="https://rigel-api-gateway-dev.relevancelab.com/rigel-csc"





// //RL server						
// export const API_CUBIX_URL ="http://192.168.101.78:8765/cassa";						
// //export const API_CUBIX_URL ="https://cubix-service.relevancelab.com/cassa";						
// export const API_BASE_URL = "https://apigateway.relevancelab.com";						
// export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";						
// export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";						
// export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";						
// export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";						
// //export const API_AUTHENTICATION_URL = "https://apigateway.relevancelab.com/prismmicro-authentication"						
// //export const API_AUTHENTICATION_URL = "http://192.168.101.78:8765/prismmicro-authentication"						
// export const API_SEARCH_URL = "https://apigateway.relevancelab.com/prismmicro-search";						
// export const API_LOGIN_URL = "https://cubix-service.relevancelab.com/rigel-mongo-auth";						
// // export const API_CASSA="http://192.168.101.78:9003/api";						
// //export const API_CASSA="http://192.168.101.53:8765/clinical-supply-chain/api"						
// //export const API_CASSA='https://rigel-api-prism-csc-dev.relevancelab.com'						
// //export const API_ALERT="https://rigel-api-gateway-dev.relevancelab.com/prism-alert"						
						
						
// //export const API_AUTHENTICATION_URL = "http://192.168.101.78:9001";						
// export const API_AUTHENTICATION_URL = "https://cassa3-api-auth-v2.web-uat.bms.com";						
						
// //export const API_CASSA="http://192.168.101.78:8765/spectra-csc"						
// export const API_CASSA="https://cassa3-0-api-csc.web-uat.bms.com"						
						
// //export const API_ALERT="http://192.168.101.78:9001"						
// export const API_ALERT="https://cassa3-api-alert.web-uat.bms.com"						
						
// export const APP_AUTH="https://cassa3-0.web-uat.bms.com/";						

// export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";						
						
// export const API_CASSA_DEMO="https://cassa3-0-api-csc.web-uat.bms.com"
// export const API_ALERT_DEMO="https://cassa3-api-alert.web-uat.bms.com"
// 192.168.101.65
// http://192.168.101.55:8765/rigel-drools


// cassa-services-dev.relevancelab.com/cassa-csc
// cassa-services-dev.relevancelab.com/cassa-alert
// cassa-services-dev.relevancelab.com/cassa-auth
//rl bms links
export const API_RISK="https://cassa-services-dev.relevancelab.com/cassa-csc"

export const API_RESULTSET_URL_AUTH = "https://apigateway.relevancelab.com/prismmicro-resultset";//for get list of users


export const API_CUBIX_URL ="http://192.168.101.78:8765/cassa";												
export const API_BASE_URL = "https://apigateway.relevancelab.com";						
export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";						
export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";						
export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";						
export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";						
						
export const API_SEARCH_URL = "https://apigateway.relevancelab.com/prismmicro-search";						
export const API_LOGIN_URL = "https://cubix-service.relevancelab.com/rigel-mongo-auth";						
					
					
export const API_AUTHENTICATION_URL = "https://cassa-services-dev.relevancelab.com/cassa-auth";						
											
export const API_CASSA="https://cassa-services-dev.relevancelab.com/cassa-csc"						
											
export const API_ALERT="https://cassa-services-dev.relevancelab.com/cassa-alert"						
						
export const APP_AUTH="https://cassa-auth-dev.relevancelab.com/";						

export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";						
						
export const API_CASSA_DEMO="https://cassa-services-dev.relevancelab.com/cassa-csc"
export const API_ALERT_DEMO="https://cassa-services-dev.relevancelab.com/cassa-alert"

// cassa-services-dev.relevancelab.com/cassa-csc
// cassa-services-dev.relevancelab.com/cassa-alert
// cassa-services-dev.relevancelab.com/cassa-auth
