import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import * as QueryString from "query-string";
import { useLocation } from "react-router-dom";
import LandinPage from "./landingpage";
import {CONFIG} from "../constants/conifiguration";
import alpsIcon from "../assets/icons/A_logo.png";
import bmsIcon from "../assets/icons/Celgene_cassa.jpg";

const App = () => {
  const location = useLocation();
  const [loginBool, setLoginBool] = useState(false);
  const { user_details } = QueryString.parse(location.search);
  
    useEffect(() => {
    if (user_details) {
      let obj = JSON.parse(user_details);
      localStorage.setItem(
        "user_details",
        JSON.stringify({
          accessToken: obj.accessToken,
          username: obj.username,
          appId: obj.appId,
        })
      );
    } else {
      setLoginBool(true);
    }
  }, []);
  const showLoading = useSelector((state) => state.commonReducer.showLoading);
  let imgPath = document.getElementById("favIcon")
  let appTitle = document.getElementById("app_title")

  if(CONFIG.product === "RL"){
    imgPath.href=alpsIcon
    appTitle.innerHTML="Alps User Management"
  }
  else if (CONFIG.product === "BMS") {
    imgPath.href=bmsIcon
    appTitle.innerHTML="User Management"
  }


  return (
    <>
      <Loading show={showLoading} />
      <Notification />
      <LandinPage loginBool={loginBool} user_details={user_details} location={location}/>
    </>
  );
};

export default App;
